import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { kayshFlushOperator, kayshOperator } from '@karacas/kaysh';
import { GenericCategorySelectionMiniV2Service } from '@prisma/components/generic-category-selection-mini-v2/generic-category-selection-mini-v2.service';
import { _aux_getEnumStringByIdPromotionTypes } from '@prisma/components/generic-select-promotion-types/services/generic-select-promotion-types.service';
import {
  _aux_getEnumStringByIdStatusActions,
  _aux_getEnumStringByIdValidationStatus,
} from '@prisma/components/generic-status-select/generic-item-status-select.service';
import { _log, _logTap, _useDummyData, _warn } from '@shared/aux_helper_environment';
import { _getFormatDate, _handleErrorWithDummyData, _ms, _throwError } from '@shared/aux_helper_functions';
import { PagedList } from 'core/models/paged-list.model';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ActionsV2ListDummyData } from './ActionsV2_list.dummyData';
import { ActionsV2ListModel, ActionsV2RowModel } from './stores/actionsV2_list.model';

const getActionsV2ListFromBE_Map = (list: PagedList<ActionsV2ListModel>): PagedList<ActionsV2RowModel> => {
  return {
    ...list,
    items: list.items.map(item => ({
      ...item,
      promotionPeriodName: item.promotionPeriod?.name || null,
      promotionPeriodCode: item.promotionPeriod?.code || null,
      validFrom: _getFormatDate(item.validFrom),
      validTo: _getFormatDate(item.validTo),
      isLoadingReport: false,
    })),
  };
};

const USE_DUMMYDATA = true;

@Injectable({
  providedIn: 'root',
})
export class ActionsV2_list_service {
  private configBase = environment.apiBaseUrl_promotionV2;
  private config = environment.promotionV2.action.api;

  constructor(private http: HttpClient, private serviceCategory: GenericCategorySelectionMiniV2Service) {}

  getList(filters): Observable<PagedList<ActionsV2RowModel>> {
    //if (USE_DUMMYDATA) return of(_cloneDeep(getActionsV2ListFromBE_Map(ActionsV2ListDummyData.ActionsV2ListWithoutMap)));
    const qs: Array<string> = ['?'];

    if (filters.pageIndex) {
      qs.push(`pageIndex=${filters.pageIndex}`);
    }

    if (filters.pageSize) {
      qs.push(`pageSize=${filters.pageSize}`);
    }

    let url = `${this.configBase}${this.config.resources.list}${qs.join('&')}`;

    const _filters = _mapFiltersToBe(filters);

    if (_filters.campaignsId) delete _filters.campaignsId;

    if (true && _filters.isDeleted === true) {
      _filters.status = [];
    }

    if (false && _filters.isSuggested === true) {
      _filters.status = []; //DES-4759
    }

    const lowLevel = this.serviceCategory.getLowLevel({
      level4: _filters.categories,
      level5: _filters.subCategories,
    });

    const payload = { ..._filters, categories: lowLevel?.level4 || [], subCategories: lowLevel?.level5 || [] };

    return this.http.post<PagedList<ActionsV2ListModel>>(url, payload).pipe(
      map(data => getActionsV2ListFromBE_Map(data)),
      tap(data => _logTap(`${ActionsV2_list_service.name}::getList (tap)\n\tdata: %o`, data)),
      kayshOperator('ActionsV2_list_service_getList', payload, {
        localStorage: false,
        maxItems: 100,
        maxTime: _ms('1s'),
        useCacheReplay: false,
      }),
      catchError(error =>
        _handleErrorWithDummyData(
          true && _useDummyData(),
          error,
          ActionsV2_list_service,
          getActionsV2ListFromBE_Map(ActionsV2ListDummyData.ActionsV2ListWithoutMap),
          'ActionsV2ListDummyData.ActionsV2ListWithoutMap'
        )
      )
    );
  }

  reset_cache_actionList() {
    _log('[reset ActionsV2_list_service_getList]');
    return kayshFlushOperator('ActionsV2_list_service_getList');
  }

  getListItems() {
    let filters = {
      promotionTypes: [],
      status: [],
      alreadyToPublish: [],
      query: '',
      pageIndex: 0,
      pageSize: 10000,
      campaignIds: [],
      categories: [],
      subCategories: [],
    };

    return this.getList(filters).pipe(
      kayshOperator('getListItem', filters, {
        localStorage: false,
        maxItems: 100,
        maxTime: _ms('1h'),
      })
    );
  }

  delete(data): Observable<any> {
    const { filter, ...rest } = data;

    const lowLevel = this.serviceCategory.getLowLevel({
      level4: filter?.categories || [],
      level5: filter?.subcategory || [],
    });
    const _filters = _mapFiltersToBe(filter);
    const url = this.configBase + this.config.resources.delete; //DES-4890

    this.reset_cache_actionList();

    return this.http
      .post<any>(url, { ...rest, filter: { ..._filters, categories: lowLevel?.level4 || [], subcategory: lowLevel?.level5 || [] } })
      .pipe(
        tap(data => _logTap(`${ActionsV2_list_service.name}::delete (tap)\n\tdata: %o`, data)),
        catchError(error => _throwError(error, ActionsV2_list_service))
      );
  }

  downloadReport(ids: number[], filters): Observable<any> {
    const url = this.configBase + this.config.resources.downloadReport;

    return this.http.post<any>(url, { Ids: ids, filters }).pipe(
      tap(data => _logTap(`${ActionsV2_list_service.name}::downloadReport (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, ActionsV2_list_service))
    );
  }

  massivePublish(data): Observable<any> {
    const { filter, ...rest } = data;
    const lowLevel = this.serviceCategory.getLowLevel({
      level4: filter?.categories || [],
      level5: filter?.subcategory || [],
    });

    const _filters = _mapFiltersToBe(filter);
    const url = this.configBase + this.config.resources.massivePublish;

    this.reset_cache_actionList();

    return this.http
      .post<any>(url, { ...rest, filter: { ..._filters, categories: lowLevel?.level4 || [], subcategory: lowLevel?.level5 || [] } })
      .pipe(
        tap(data => _logTap(`${ActionsV2_list_service.name}::massivePublish (tap)\n\tdata: %o`, data)),
        catchError(error => _throwError(error, ActionsV2_list_service))
      );
  }

  clone(data): Observable<any> {
    const url = this.configBase + this.config.resources.listItemClone;

    this.reset_cache_actionList();

    return this.http.post<any>(url, data).pipe(
      tap(data => _logTap(`${ActionsV2_list_service.name}::clone (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, ActionsV2_list_service))
    );
  }

  // Nuevo modal cancelar promociones (PRO-1149)
  cancelV2(cancelData: { id: number; reason: { observation: string } }): Observable<any> {
    const url = this.configBase + this.config.resources.listItemCancel;
    const payload = {
      id: cancelData.id,
      reason: {
        observation: cancelData.reason.observation,
      },
    };
    return this.http.post<any>(url, payload).pipe(
      tap(data => _logTap(`${ActionsV2_list_service.name}::cancel (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, ActionsV2_list_service))
    );
  }

  cancel(id): Observable<any> {
    const url = this.configBase + this.config.resources.listItemCancel;
    return this.http.post<any>(url, { id: id }).pipe(
      tap(data => _logTap(`${ActionsV2_list_service.name}::cancel (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, ActionsV2_list_service))
    );
  }

  actionReport(id): Observable<any> {
    if (id == null || id == 0) {
      _warn('invalid id', id);
    }

    const url = `${this.configBase + this.config.resources.actionPromotionReport}?promotionId=${id}`;

    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.get(url, { headers, responseType: 'text' }).pipe(
      tap(data => _logTap(`${ActionsV2_list_service.name}::actionReport (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, ActionsV2_list_service))
    );
  }
}

export const _mapFiltersToBe = filters => {
  return {
    ...filters,
    status: (filters.status || []).map(s => _aux_getEnumStringByIdStatusActions(s, false)),
    validationStatus: (filters.validationStatus || []).map(s => _aux_getEnumStringByIdValidationStatus(s, false)),
    promotionTypes: (filters.promotionTypes || []).map(p => _aux_getEnumStringByIdPromotionTypes(p, false)),
    campaignIds: filters.campaignsId,
  };
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { __log, _isDev, _isFeDev, _isLab, _isProd, _isStaging, _log, _warn } from '@shared/aux_helper_environment';
import { _cloneDeep, _timeout } from '@shared/aux_helper_functions';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { GlobalAlertService } from './ian-core-singleton.service';
import { _getBrandConfigLS, _setBrandConfigLS } from './ian-prepareBrandCustomizationV2';
const JSON5 = require('json5');

const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
export const _keyUserFeatureFlags = _keyVer + 'userFeatureFlags' + '_aws';

export enum FeatureFlagsKeysEnum {
  'FeatureFlag__DEV-01' = 'FeatureFlag__DEV-01', //' OLD: DEV-01__SHOW_DATA_QUALITY_REPORT',
  'FeatureFlag__PRO-540' = 'FeatureFlag__PRO-540', //' OLD: PRO-540__FORZAR_ACTIVAR_NOTIFICACIONES',
  'FeatureFlag__CAT-214' = 'FeatureFlag__CAT-214', //' OLD: CAT-214__ESPACIOS_BLOQUEADOS',
  'FeatureFlag__CAT-469' = 'FeatureFlag__CAT-469', //' OLD: CAT-469__ACCIONES_MASIVAS',
  'FeatureFlag__CAT-388' = 'FeatureFlag__CAT-388', //' OLD: CAT-388__NUEVA_PANTALLA_GENERACION_TEMPLATE',
  'FeatureFlag__DES-3172' = 'FeatureFlag__DES-3172', //' OLD: DES-3172__CONFIG_DATE_FORMAT_ON_SETTING_PANEL',
  'FeatureFlag__DES-3662' = 'FeatureFlag__DES-3662', //' OLD: DES-3662__VIEW_COMPETITION_PRICE',
  'FeatureFlag__DES-3XXX' = 'FeatureFlag__DES-3XXX', //' OLD: DES-3XXX__AUTOCOMPLETE_ITEM',
  'FeatureFlag__DES-3898' = 'FeatureFlag__DES-3898', //' OLD: DES-3898__NEW-DECISION-SUMMARY',
  'FeatureFlag__DES-4362' = 'FeatureFlag__DES-4362', //' OLD: DES-4362__STORE_REPORT_PDV',
  'FeatureFlag__DES-4510' = 'FeatureFlag__DES-4510', //' OLD: DES-4510__SPC-FEATURES-PARA-POC-SODIMAC',
  'FeatureFlag__DES-4971' = 'FeatureFlag__DES-4971', //' OLD: DES-4971__LISTADO-DE-MATERIAL-POP',
  'FeatureFlag__GOD-188' = 'FeatureFlag__GOD-188', //' OLD: GOD-188__IMPLEMENTATION-MODE',
  'FeatureFlag__CAT-195' = 'FeatureFlag__CAT-195', //' OLD: CAT-195__SHOW_ONLY_SUGGESTIONS_FILTER',
  'FeatureFlag__PREC-287' = 'FeatureFlag__PREC-287', //' OLD: PREC-287__TAB_DE_EQUIVALENCIAS_EN_IM',
  'FeatureFlag__PREC-221' = 'FeatureFlag__PREC-221', //' OLD: PREC-221__REVISION_DEENDPOINT_VIEJO_DE_ATTR',
  'FeatureFlag__PRO-357' = 'FeatureFlag__PRO-357', //' OLD: PRO-357__NESTED_TAGS_IN_SCOPES',
  'FeatureFlag__PREC-620' = 'FeatureFlag__PREC-620', //' OLD: PREC-620__PRODUCTOS_NUEVOS',
  'FeatureFlag__PREC-857' = 'FeatureFlag__PREC-857',
  'FeatureFlag__PREC-864' = 'FeatureFlag__PREC-864',
  'FeatureFlag__CAT-250' = 'FeatureFlag__CAT-250',
  'FeatureFlag__CAT-713' = 'FeatureFlag__CAT-713',
  'FeatureFlag__CAT-955' = 'FeatureFlag__CAT-955',
  'FeatureFlag__CAT-663' = 'FeatureFlag__CAT-663',
  'FeatureFlag__PREC-875' = 'FeatureFlag__PREC-875',
  'FeatureFlag__CAT-688' = 'FeatureFlag__CAT-688',
  'FeatureFlag__PREC-817' = 'FeatureFlag__PREC-817',
  'FeatureFlag__PRO-1059' = 'FeatureFlag__PRO-1059', // abm y listado de procesos promocionales
  'FeatureFlag__PRO-1224' = 'FeatureFlag__PRO-1224', // combos edit v3
  'FeatureFlag__PRO-1149' = 'FeatureFlag__PRO-1149', // Bitacora de Promociones
  'FeatureFlag__CAT-933' = 'FeatureFlag__CAT-933',
  'FeatureFlag__CAT-1021' = 'FeatureFlag__CAT-1021',
  'FeatureFlag__CORE-46' = 'FeatureFlag__CORE-46', // showComplianceFF
  'FeatureFlag__PRO-1421' = 'FeatureFlag__PRO-1421', // combos edit v3
  'FeatureFlag__PRO-1540' = 'FeatureFlag__PRO-1540', // cambio de endpoints popMaterialList
}

export type FeatureFlagsModel = {
  [key in FeatureFlagsKeysEnum]: boolean;
};

export type FeatureFlagsModel_AWS_object = {
  [key in FeatureFlagsKeysEnum]: {
    value: boolean;
    description?: string;
    editable?: boolean;
  };
};

export function normaliceFFkey(key: string): string {
  if (key == null || key.includes('-')) return key;
  return key.replace(/([a-zA-Z]+)(\d+)/, '$1-$2');
}

const featureFlagsAWSModel_to_featureFlagsModel = (FF: FeatureFlagsModel_AWS_object): FeatureFlagsModel => {
  let rv = {};

  _getSafeKeys(FF).forEach(key => {
    rv[key] = FF[key]?.value != null ? FF[key].value : FF[key] ?? false;
  });

  return rv as FeatureFlagsModel;
};

const _global = window as any;

const _getSafeKeys = obj => {
  if (obj == null) return [];
  return Object.keys(obj);
};

@Injectable({
  providedIn: 'root',
})
export class DevSettingsService {
  private FF_level1_AWS: FeatureFlagsModel_AWS_object = null;
  private FF_level1_root: FeatureFlagsModel = null;
  private FF_level3_userLocalStorage: FeatureFlagsModel = null;

  private FF_default_values: FeatureFlagsModel = null;
  private FF_final_values: FeatureFlagsModel = null;

  private readonly valKey = _keyUserFeatureFlags;
  private showDevSetings = false;
  private userRole = null;
  private userData = null;

  constructor(private http: HttpClient, private globalAlertService: GlobalAlertService, private oidcSecurityService: OidcSecurityService) {
    this.FF_level1_root = null;
  }

  public __canUseDevMode() {
    let email = this.userData?.email;

    if (_isDev() || _isLab() || _isStaging()) {
      return true;
    }

    if (email && (email.includes('@iantech.net') || email.includes('@prismaretail.ai'))) {
      return true;
    }

    return false;
  }

  //CORE-24
  public initFeatureFlags($AWS_FeatureFlagsRaw: string = null) {
    try {
      this.FF_level1_AWS = JSON5.parse($AWS_FeatureFlagsRaw); //Parsea FLAGS de AWS
      this.FF_level1_root = featureFlagsAWSModel_to_featureFlagsModel(this.FF_level1_AWS); //Para el nivel ROOT las convierte

      if (false && _isFeDev()) {
        _log('FF_level1_AWS', this.FF_level1_AWS);
        _log('FF_level1_root', this.FF_level1_root);
      }
    } catch (e) {
      console.error('NO AWS FeatureFlags', e, $AWS_FeatureFlagsRaw);
    }

    this.oidcSecurityService.getUserData().subscribe(async (data: any) => {
      if (data?.role?.length && this.userRole == null) {
        this.userRole = data?.role;
        this.userData = data;
        this.setGlobalMethods();
      }
    });

    this.internalInitFeatureFlags();
  }

  private internalInitFeatureFlags() {
    this.FF_level3_userLocalStorage = this.loadUserStorageFeatureFlagsValues();

    this.rebuildFeatureFlagsValues();

    this.warningNoKeysDefined();

    if (true)
      _log('\nINIT initFeatureFlags OK', {
        userRole: this.userRole,
        userLocalStorage: this.FF_level3_userLocalStorage,
        canUseDevMode: this.__canUseDevMode(),
        FF_final_values: this.FF_final_values,
        FF_level1_AWS: this.FF_level1_AWS,
      });
  }

  private warningNoKeysDefined() {
    if (this.FF_level1_AWS == null && _isDev()) return;

    const featureFlags = Object.keys(this.__getAllFeatureFlagsValues());
    const featureFlags_EnumKeysLocal = this.__getAllFeatureFlagsLocal();

    let notDefined = [];

    featureFlags_EnumKeysLocal.forEach(k => {
      if (!featureFlags.includes(k)) {
        notDefined.push(k);
      }
    });

    if (notDefined.length > 0) _warn(['FF no definidas en AWS:'], notDefined);
  }

  private setGlobalMethods() {
    if (!this.__canUseDevMode()) return;

    let $window: any = window;

    if (true && $window) {
      $window.__emulateEndPointError = () => this.__emulateEndPointError();
      $window.__resetLocalStorage = () => this.__resetLocalStorage(true);
      $window.__resetFeatureFlagsUserValues = () => this.__resetFeatureFlagsUserValues(true);
      $window.__setCanShowDevSettings = () => this.__setCanShowDevSettings(true);
      $window.__setLocalStorage = ($obj: string | Object, reset = true) => this.__setLocalStorage($obj, reset);
      $window.__setBrandConfigLS = ($obj: string | Object, reset = true) => this.__setBrandConfigLS($obj, reset);
    }
  }

  private loadUserStorageFeatureFlagsValues() {
    let value = localStorage.getItem(this.valKey);

    if (value != null) {
      try {
        let rv = JSON5.parse(value);

        console.log('[loadUserStorageFeatureFlagsValues]', rv);

        if (rv && _getSafeKeys(rv).length > 0) {
          this.__setCanShowDevSettings(true, true);
          return rv;
        }
      } catch (e) {
        console.error(e);
        localStorage.removeItem(this.valKey);
      }
    }

    return null;
  }

  private rebuildFeatureFlagsValues() {
    this.FF_default_values = _cloneDeep({ ...this.FF_level1_root });
    this.FF_final_values = _cloneDeep({ ...this.FF_default_values, ...this.FF_level3_userLocalStorage });

    if (_global) {
      _global._info = _global._info ? _global._info : {};
      _global._info._featureFlags = this.FF_final_values;
      _global._info._featureFlagsValidList = this.getValidFeatureFlagsKeys();
    }
  }

  private getValidFeatureFlagsKeys() {
    return _getSafeKeys(FeatureFlagsKeysEnum);
  }

  public async restartApp(reset) {
    this.rebuildFeatureFlagsValues();

    if (reset) {
      _log('[restartApp]');

      await _timeout(16);

      (window as any)?.location?.reload?.();
    }
  }

  public hasFeatureFlag(key: FeatureFlagsKeysEnum) {
    return this.FF_final_values?.[key];
  }

  public get __canShowDevSettings(): boolean {
    if (!this.__canUseDevMode()) return false;

    return this.showDevSetings;
  }

  public __getAllEnabledFeatureFlagsValues() {
    return _getSafeKeys(this.FF_final_values).filter(e => this.FF_final_values?.[e] === true);
  }

  public __setCanShowDevSettings(val = true, force = false) {
    if (force === false && !this.__canUseDevMode()) return false;

    this.showDevSetings = !!val;
  }

  //Para saber el valor default de una KEY
  public __getFeatureFlagsDefault(key: string) {
    return this.FF_default_values?.[key] === true;
  }

  public __getAllFeatureFlagsValues() {
    return { ...this.FF_final_values };
  }

  public __getAllFeatureFlagsLocal() {
    return _getSafeKeys(FeatureFlagsKeysEnum);
  }

  public __getAllFeatureFlagsValues_AWS_object(): FeatureFlagsModel_AWS_object {
    return { ...this.FF_level1_AWS };
  }

  public __getDefaultValues() {
    return { ...this.FF_default_values };
  }

  public __getUserValues() {
    return { ...this.FF_level3_userLocalStorage };
  }

  public __setFeatureFlagsUserValues(obj, reset = false) {
    if (obj == null || !this.__canUseDevMode()) return;

    let rv = {} as any;

    _getSafeKeys(obj).forEach(key => {
      let newVal = Boolean(obj[key]);
      let defaultValue = Boolean(this.FF_default_values?.[key]);

      if (newVal !== defaultValue) rv[key] = newVal; //Filtra los valore no default
    });

    if (!(_getSafeKeys(rv).length > 0)) {
      this.__resetFeatureFlagsUserValues(reset);
      return;
    }

    localStorage.setItem(this.valKey, JSON.stringify(rv));
    this.FF_level3_userLocalStorage = rv;
    console.log('[__setUsersValue]', obj, rv);

    this.restartApp(reset);
  }

  public __resetFeatureFlagsUserValues(reset = true) {
    _log('[__resetUsersValue]');

    this.FF_level3_userLocalStorage = null;

    localStorage.removeItem(this.valKey);

    this.restartApp(reset);
  }

  public __hasLocalStorage(): boolean {
    if (this.FF_level3_userLocalStorage == null || !this.__canUseDevMode()) return false;

    return !!(_getSafeKeys(this.FF_level3_userLocalStorage)?.length > 0);
  }

  public __emulateEndPointError() {
    if (!this.__canUseDevMode()) return false;

    _log('[emulateEndPointError]');

    this.http.get('/api/fakeError').subscribe(data => _log(data));
  }

  public __resetLocalStorage(reset = true) {
    let ls = _cloneDeep(window?.localStorage);
    if (ls == null) return;

    let validKeys = [
      _keyVer + 'code_verifier',
      _keyVer + 'authorizationDataIdToken',
      _keyVer + 'userData',
      _keyVer + 'storage_silent_renew_running',
      _keyVer + 'userData_permission',
      _keyVer + 'codeUrl',
      _keyVer + 'authNonce',
      _keyVer + 'authorizationResult',
      _keyVer + 'authStateControl',
      _keyVer + 'session_state',
      _keyVer + 'authorizationData',
      _keyVer + '_isAuthorized',
      _keyVer + '__token',
    ];

    Object.keys(ls).forEach(key => {
      if (!validKeys.includes(key)) {
        localStorage.removeItem(key);
      }
    });

    this.restartApp(reset);
  }

  public __setLocalStorage($obj: string | object, reset = true) {
    if (!this.__canUseDevMode()) return;

    let rv: any = $obj === 'string' ? JSON.parse($obj) : $obj;

    if (true) _log('[__setLocalStorage]', $obj, rv);

    Object.keys(rv).forEach(key => {
      let keyVal = rv[key];
      let val = keyVal !== null && typeof keyVal === 'object' ? JSON.stringify(rv[key]) : keyVal;
      localStorage.setItem(key, val);
    });

    this.restartApp(reset);
  }

  public __setBrandConfigLS(brandConfig, reset = true) {
    if (!this.__canUseDevMode()) return;

    //__setBrandConfigLS({ brandLogoAsset: "./assets/brands/oxxo.png" })
    //__setBrandConfigLS({ featuresEnabled: { blocksEnabled: { 'PRISMA-ASSISTANT': false } } })

    _setBrandConfigLS(brandConfig);
    this.restartApp(reset);
  }

  public __getBrandConfigLS() {
    return _getBrandConfigLS();
  }
}

import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, pairwise, takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation as appNavigation } from 'app/navigation/navigation';

import { _clearAllLocalStorageData, _getEnv, _isDev, _isFeDev, _isLab, _isStaging, _log, _warn } from '@shared/aux_helper_environment';
import { _cloneDeep, _debounceDecorator, _equal, _extendObjDepp, _get, _timeout, _uniqueElementsByKey } from '@shared/aux_helper_functions';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { NotificationService } from './layout/components/notifications-panel/notifications-panel.service';
import { MessagingService } from './services/messaging.service';

import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { GenericCategorySelectionMiniV2Service } from '@prisma/components/generic-category-selection-mini-v2/generic-category-selection-mini-v2.service';
import { getEnabledBlockVal, initEnabledBlocks } from '@shared/disabled_blocks';
import { ConsoleLogger } from 'core/services/console_logger.service';
import { _keyUserFeatureFlags, DevSettingsService, FeatureFlagsKeysEnum } from 'core/services/dev-settings.service';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';
import { locale as en_base } from './i18n/en_base';
import { locale as es_base } from './i18n/es_base';
import { locale as pt_base } from './i18n/pt_base';
import { _setUseUXV2 } from './layout/components/global-settings-panel/global-settings-panel.component';
import { StatusConecctionService } from './services/status_conecction.service';

const _devTest = false;
const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
const _checkingUpSellRedirect = true;
const _checkingBlockedUrl = true; //CORE-25
let _globalUpSellingRedirectsMap = null;
let userData_permission: any = null;

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  auth = false;
  codeUrl = '';

  _isforcedLang = false;
  _window = window ? (window as any) : {};
  _lang = null;
  _langs = null;
  _initData = false;

  _finishLoading = false;
  _blockedUrls = []; //CORE-25

  // Private
  private _unsubscribeAll: Subject<void> = new Subject();
  private _reports: [
    {
      id: string;
      section: string;
      strictSection: boolean;
      description: string;
      enable: boolean;
      url: string;
    }
  ];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private oidcSecurityService: OidcSecurityService,
    private messagingService: MessagingService,
    private statusConecctionService: StatusConecctionService,
    private notificationServicem: NotificationService,
    private notificationService: NotificationService,
    private permissionsService: NgxPermissionsService,
    private prismaDynamicEnv: PrismaDynamicEnv,
    private servCategory: GenericCategorySelectionMiniV2Service,
    private router: Router,
    private cd: ChangeDetectorRef,
    private consoleLogger: ConsoleLogger,
    private devSettingsService: DevSettingsService
  ) {
    this.setDevStorageForcedValues();

    _setUseUXV2(); //_UXV2_

    if (_checkingBlockedUrl) this.checkBlockedUrl();
    if (_checkingUpSellRedirect) this.checkUpSellRedirect();
  }

  ngOnInit(): void {
    this._tryPermissionCached();

    this._initCurrentNav();

    this._setDebugInfo();

    this._checkIfMobile();

    this._getInitData();

    this._window._translateService = this._translateService;
    this._window._fuseTranslationLoaderService = this._fuseTranslationLoaderService;

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        pairwise()
      )
      .subscribe((e: any[]) => {
        (window as any)._info.lastUrl = e && e[0] && e[0]['url'] ? e[0]['url'] : null;
      });

    this.setglobalChangeDetectorRef();

    this._translateService.onLangChange.subscribe(() => {
      // Actualizar el navigation con los valores traducidos
      this.navigation = this._fuseNavigationService.getCurrentNavigation();
    });
  }

  setglobalChangeDetectorRef() {
    if (window) {
      (window as any)._globalChangeDetectorRef = () => {
        if (this.cd) {
          console.log('[detectChanges]');
          this.cd.detectChanges();
        }
      };
    }
  }

  @_debounceDecorator(512)
  setSplahLoading(val = false) {
    val ? this._fuseSplashScreenService.show() : this._fuseSplashScreenService.hide();
    if (!val) {
      this._finishLoading = true;
    }
  }

  setDevStorageForcedValues() {
    if (!_isDev()) return null;

    const dev_ls = _getEnv('dev_local_storage');

    //[TODO:] eliminar FF
    if (dev_ls != null) {
      for (let [key, value] of Object.entries(dev_ls)) {
        // no copia los FF
        if (key === _keyUserFeatureFlags) return;

        if (typeof value === 'string') localStorage.setItem(key, String(value));
      }
    }
  }

  private _tryPermissionCached() {
    // TRY LOAD userData_permission
    userData_permission = localStorage.getItem(_keyVer + 'userData_permission');

    if (userData_permission && this.permissionsService && this.permissionsService.loadPermissions) {
      try {
        userData_permission = JSON.parse(userData_permission);
        if (userData_permission && userData_permission.length) {
          this.permissionsService.loadPermissions(userData_permission);
          if (false && _devTest) {
            _log('[userData_permission from localStorage]');
          }
        } else {
          userData_permission = null;
        }
      } catch (e) {
        userData_permission = null;
      }
    }

    if (window.location && window.location.toString().length > 0) {
      localStorage.setItem(_keyVer + 'codeUrl', window.location.toString());
      if (false && _devTest) {
        console.log('%c[localStorage.setItem(codeUrl]', 'color:#f38519', { window_location_toString: window.location.toString() });
      }
    }

    // Refresh auth status
    if (this.oidcSecurityService.moduleSetup) {
      if (window.location.hash || window.location.href.includes('?code')) {
        this.doCallbackLogicIfRequired();
      }
    } else {
      this.oidcSecurityService.onModuleSetup.pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged()).subscribe(() => {
        if (window.location.hash || window.location.href.includes('?code')) {
          this.doCallbackLogicIfRequired();
        }
      });
    }
  }

  private _initCurrentNav() {
    this.navigation = appNavigation;

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');
  }

  private _setDebugInfo() {
    // Debug Info
    if (window) {
      this._window._info = this._window._info || {};
      if (!_get(window, '_info._environment')) {
        this._window._info._environment = environment;
      }
      if (_get(window, '_info._environment')) {
        this._window._info._production = environment.production;
      }
    }
  }

  private _checkIfMobile() {
    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }
  }

  private async _setAdminSecurityItem(userData) {
    if (userData?.permission == null) return;

    const hasAdminSecurityPermission = userData?.permission?.includes('admin_security');

    if (!hasAdminSecurityPermission) {
      this.markUrlToBlock(this._fuseNavigationService.getNavigationItem('admin-security-users')?.url); //CORE-25 > Marca la URL como bloqueada
      this.markUrlToBlock(this._fuseNavigationService.getNavigationItem('admin-security-roles')?.url); //CORE-25 > Marca la URL como bloqueada

      _warn('no admin-security permission');
      this._fuseNavigationService.removeNavigationItem('admin-security');
    }
  }

  private async _handleNavigationItemsbyBrandConfig() {
    if (
      this.prismaDynamicEnv.getConf('brandCustomization.featuresEnabled.mainMenu.admin-master-dynamic-entities') !== true &&
      !_isFeDev()
    ) {
      let dynamicEntities_entryId = 'admin-master-dynamic-entities';
      let dynamicEntities_entryUrl = this._fuseNavigationService.getNavigationItem(dynamicEntities_entryId)?.url;
      this.markUrlToBlock(dynamicEntities_entryUrl);
      this._fuseNavigationService.removeNavigationItem(dynamicEntities_entryId);
    }
  }

  private async _showPromotionReportsV2() {
    const showPromotionReportsV2 = true;

    if (showPromotionReportsV2) {
      this._fuseNavigationService.removeNavigationItem('promotionsV2-productionList');
      this._fuseNavigationService.removeNavigationItem('promotionsV2-promotionOutput');
      this._fuseNavigationService.removeNavigationItem('promotionsV2-logisticsReport');
      this._fuseNavigationService.removeNavigationItem('promotionsV2-promotionReport');
    } else {
      this._fuseNavigationService.removeNavigationItem('promotionsV2-production-reports');
    }
  }

  private _getInitData() {
    combineLatest([this.oidcSecurityService.getIsAuthorized(), this._fuseConfigService.config])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([auth, config]) => {
        this.updateConfig(auth, config);

        if (!auth) this.setSplahLoading(false);
        if (!auth) _clearAllLocalStorageData();

        const dev_ls = _getEnv('dev_local_storage');
        const dev_authToken = localStorage.getItem(_keyVer + 'authorizationDataIdToken');
        const dev_lsToken = dev_ls?.[_keyVer + 'authorizationDataIdToken'];
        const dev_isSamedevToken = dev_authToken?.length && dev_lsToken === dev_authToken;
        const dev_isAuth = localStorage.getItem(_keyVer + '_isAuthorized') === 'true';

        //Si es dev y tiene dev_local_storage en lugar de echar al usuario hace un refresh
        if (true && _isFeDev() && dev_isAuth && dev_isSamedevToken && !auth && !this.auth) {
          (window as any)?.location?.reload?.();
          return;
        }

        if (!this.auth && auth) {
          //First login
          this.setSplahLoading(true);
          this.oidcSecurityService
            .getUserData()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => this.loginOk(data));
        }

        if (this._window && this._window._info) {
          this._window._info._prismaDynamicEnvData = this.prismaDynamicEnv.getData();
        }

        this.auth = auth;
        if (!this._initData) this._getInitDataOK();

        this._initData = true;
        if (this.auth && (_devTest || _isFeDev())) {
          _log('%c[APP ALL DATA LOADED]', 'color: #bada55', { auth, prismaDynamicEnvData: this.prismaDynamicEnv.getData() });
        }
      });
  }

  private _getInitDataOK() {
    const canUseConsoleLogger =
      true &&
      (_isDev() ||
        _isLab() ||
        _isStaging() ||
        this.prismaDynamicEnv.getConf('brandCustomization.featuresEnabled.blocksEnabled.USE_CONSOLE_LOGGER'));

    if (canUseConsoleLogger) this.consoleLogger.init();

    //show promotions configurations nav option
    this._promotionsNavigationConfig();

    // Remove items
    this.configItems();

    // Add dynamic reports
    this.addReports();

    //filter promotion reports v2
    this._showPromotionReportsV2();

    this._handleNavigationItemsbyBrandConfig();

    //Load and set lang
    this.initLang();

    this.setTheme();
  }

  _setedThemeDefault = false;
  async setTheme() {
    if (this._setedThemeDefault) return;

    await _timeout(0);

    const storedTheme = localStorage.getItem(_keyVer + 'colorTheme');

    if (storedTheme === 'theme-dark') this._fuseConfigService.setConfig({ colorTheme: storedTheme });

    this._setedThemeDefault = true;
  }

  getCategories() {
    this.servCategory.setCategories();
  }

  private async loginOk(userData) {
    this.setUserData(userData);

    this.notificationsSuscribe(userData);

    //Force set languaje
    await this.forceSetLanguaje();

    if (this._window && true) {
      this._window.forceSetLanguaje = this.forceSetLanguaje;
    }

    //Remove Loader
    this.setSplahLoading(false);

    this.statusConecctionService.initStatusConecction();

    //obtiene las categorias del sistema
    if (this.auth) this.getCategories();

    this._setAdminSecurityItem(userData);
  }

  private setUserData(userData) {
    if (userData) {
      // userData.permission is Update
      if (userData.permission && !_equal(userData.permission, userData_permission)) {
        // Load user permissions / id:YGx1s-CJF1
        this.permissionsService.loadPermissions(userData.permission);

        // Save userData_permission
        localStorage.setItem(_keyVer + 'userData_permission', JSON.stringify(userData.permission));
        if (false && _devTest) {
          _log('[userData_permission TO localStorage]');
        }
      }

      if (false && _devTest) {
        _log('[getUserData]', { userData });
      }

      this._window._info = this._window._info || {};

      //TODO: Rol Harcoded id:nhVHfcEk5t
      const HARDCODE_ROLE = true;
      const HARD_CODED_ROLE = { plazaRole: 'PLAZA', categoryRole: 'Categoria' };
      this._window._info._userData = { ...userData, ...(HARDCODE_ROLE ? HARD_CODED_ROLE : null) };
    }
  }

  private notificationsSuscribe(userData) {
    if (userData) {
      // setup firebase to receive messages
      this.messagingService.requestPermission();

      this.messagingService.currentMessage.pipe(takeUntil(this._unsubscribeAll)).subscribe(msg => {
        if (msg && msg.notification) {
          if (true) _log('🔔 app setNewItem', msg);
          this.notificationService.setNewItem(msg.notification);
        } else {
          if (true) _warn('🔔 no msg', msg);
        }
      });

      //Get BE messages
      setTimeout(() => {
        this.notificationServicem.setNotifItemsFromBE();
      }, _getEnv('notifications.delayToGetFirstsNotifications', 100));
    }
  }

  private async initLang() {
    this._langs = ['es', 'en', 'pt'];
    this._lang = this.prismaDynamicEnv.getConf('brandCustomization.langBase', 'es');

    await _timeout(1);

    if (getEnabledBlockVal('LANGUAJE-USER-SAVE-LOCAL') && getEnabledBlockVal('LANGUAJE-USER-CAN-CHANGE')) {
      const langTmp = localStorage.getItem(_keyVer + 'user=lang');
      if (langTmp != null) this._lang = langTmp;
    }

    if (this._window && this._window._info) {
      this._window._info._lang = this._lang;
      this._window._info._langs = this._langs;
    }

    const brandLangExtendedEs = this.prismaDynamicEnv.getConf('brandLangExtended') || {};
    this._fuseTranslationLoaderService.loadTranslations(_extendObjDepp(es_base, brandLangExtendedEs));
    this._fuseTranslationLoaderService.loadTranslations(en_base);
    this._fuseTranslationLoaderService.loadTranslations(pt_base);

    // Add languages
    this._translateService.addLangs(this._langs);

    // Set the default language
    this._translateService.setDefaultLang(this._lang);

    // Use a language
    this._translateService.use(this._lang);

    // Get languages
    if (true && _devTest) {
      _log('%c[LANG]', 'color: #bada55', { langBase: this._lang, langsBase: this._langs });
    }
  }

  private async _setUpUpsell(upSellingRedirects) {
    //[WIP] DES-14
    (window as any)._info = (window as any)._info ? (window as any)._info : {};
    (window as any)._info._upSellingRedirects = upSellingRedirects;

    const swapUrls = upSellingRedirects?.swapUrls;

    if (!upSellingRedirects?.enabled || !swapUrls || !this._fuseNavigationService) return;
    let param = 0;

    if (_globalUpSellingRedirectsMap == null) _globalUpSellingRedirectsMap = {};

    Object.entries(swapUrls).forEach(([key, value]) => {
      let item = key;
      let redirectEnabled = (value as any)?.enabled === true;
      let redirectUrl = (value as any)?.url;
      if (redirectEnabled) {
        let itemNavObj = this._fuseNavigationService.getNavigationItem(item);
        if (itemNavObj) {
          let paramStr = '/' + param;
          let oldUrl = itemNavObj?.url;
          let newUrl = redirectUrl + paramStr;

          if (oldUrl || !_globalUpSellingRedirectsMap[oldUrl]) _globalUpSellingRedirectsMap[oldUrl] = newUrl;

          this._fuseNavigationService.updateNavigationItem(item, { url: newUrl });
        } else {
          console.warn('_setUpUpsell no item', item);
        }
      }
      param++;
    });
  }

  //CORE-25 > Si una URL está marcada como isBlocked la detiene
  private checkBlockedUrl() {
    this.router.events.pipe(filter(e => e instanceof RouterEvent)).subscribe((e: RouterEvent) => {
      let url = e?.url;

      if (url == null || !(url?.length > 1) || !(this._blockedUrls?.length > 0)) return;

      let isBlocked = null;

      this._blockedUrls.forEach(blockedUrl => {
        if (url.indexOf(blockedUrl) === 0) isBlocked = blockedUrl;
      });

      //En dev y lab no se bloquean las URLS
      if (isBlocked != null && (_isDev() || _isLab())) {
        console.warn('[URL isBlocked (CORE-25)]', { url, isBlocked, _blockedUrls: this._blockedUrls });
        isBlocked = null;
      }

      if (isBlocked != null) {
        this.router.navigate(['/unauthorized']);

        if (this.devSettingsService?.__canUseDevMode?.()) {
          console.error('[URL isBlocked (CORE-25)]', { url, isBlocked, _blockedUrls: this._blockedUrls });
        }
      }
    });
  }

  //CORE-25
  private markUrlToBlock(url: string) {
    if (url == null || !url?.includes?.('/')) return;

    this._blockedUrls.push(url);

    if (this._window._info != null) {
      this._window._info._blockedUrls = this._blockedUrls;
    }
  }

  private checkUpSellRedirect() {
    this.router.events.pipe(filter(e => e instanceof RouterEvent)).subscribe((e: RouterEvent) => {
      if (_globalUpSellingRedirectsMap == null) return;
      let url = e?.url;
      if (url) {
        let newUrl = _globalUpSellingRedirectsMap[url];
        if (newUrl) this.router.navigate([newUrl]);
      }
    });
  }

  private async _disableMenuItems($featuresEnabledMainMenu, navigationItems) {
    let featuresEnabledMainMenu = _cloneDeep($featuresEnabledMainMenu);

    let reports = this.prismaDynamicEnv.getConf('brandCustomization.reports');

    if (reports?.length && featuresEnabledMainMenu != null) {
      reports.forEach(el => {
        if (el?.id != null) featuresEnabledMainMenu[el.id] = true;
      });
    }

    //Overwrites
    if ((_isDev() || _isLab() || _isStaging()) && featuresEnabledMainMenu != null) {
      featuresEnabledMainMenu['prices-reports-fuels-dashboard'] = true;
    }

    //Overwrites GOD-188 > GOD-188 prende el importador general
    if (this.devSettingsService.hasFeatureFlag(FeatureFlagsKeysEnum['FeatureFlag__GOD-188'])) {
      featuresEnabledMainMenu['admin'] = true;
      featuresEnabledMainMenu['admin-general-importer'] = true;
      featuresEnabledMainMenu['admin-interfaces-log'] = true;
      _log('[admin-general-importer] FeatureFlag__GOD-188', true);
    }

    //Si el id del item no está en true en el config lo deshabilita
    (window as any)._info = (window as any)._info ? (window as any)._info : {};
    (window as any)._info._featuresEnabledMainMenuDisabled = [];

    if (featuresEnabledMainMenu) {
      navigationItems.forEach(item => {
        let id = item?.id;

        if (id && featuresEnabledMainMenu[id] !== true) {
          let itemUrl = this._fuseNavigationService.getNavigationItem(id)?.url;
          this.markUrlToBlock(itemUrl); //CORE-25 > Marca la URL como bloqueada

          if (this._fuseNavigationService) this._fuseNavigationService.removeNavigationItem(id);
          (window as any)._info._featuresEnabledMainMenuDisabled.push(id);
        }
      });
    }

    if (window) {
      (window as any)._info._featuresEnabledMainMenu = featuresEnabledMainMenu;
    }

    //Checkea que reportes tenga hijos, si no lo remueve
    let reportRoot = this._fuseNavigationService.getNavigationItem('reports');
    if (reportRoot && !(reportRoot?.children?.length > 0)) {
      if (this._fuseNavigationService) this._fuseNavigationService.removeNavigationItem('reports');
    }
  }

  private async forceSetLanguaje($lang = null) {
    if (this._isforcedLang && $lang === null) return;

    if ($lang != null && (window as any)?._translateService?.getDefaultLang() == $lang) {
      return;
    }

    await _timeout(16);
    this._isforcedLang = true;

    if ($lang != null) this._lang = $lang;

    this._translateService.setDefaultLang(this._lang);
    if ($lang === null) this._translateService.use(null);

    if ($lang != null && getEnabledBlockVal('LANGUAJE-USER-SAVE-LOCAL') && getEnabledBlockVal('LANGUAJE-USER-CAN-CHANGE')) {
      localStorage.setItem(_keyVer + 'user=lang', $lang);
    }

    if ((!true && _devTest) || $lang) {
      _log('%c[FORCE SET LANG]', 'color: #bada55', { langBase: this._lang, langsBase: this._langs });
    }

    if ($lang != null) {
      (window as any)?._fuseTranslationLoaderService?.auxReloadCurrentRoute();
    }

    return;
  }

  private async configItems() {
    await _timeout(0);

    //[WIP] 3b2acg
    const featuresEnabledMainMenu = this.prismaDynamicEnv.getConf('brandCustomization.featuresEnabled.mainMenu');
    const navigationItems = this._currentNavigationFlatChilds((this._fuseNavigationService.getCurrentNavigation() || [])[0] || []);
    const upSelling = this.prismaDynamicEnv.getConf('brandCustomization.upSellRedirects');
    const blocksEnabled = this.prismaDynamicEnv.getConf('brandCustomization.featuresEnabled.blocksEnabled');

    // INIT DISABLE BLOCKS
    if (blocksEnabled) initEnabledBlocks(blocksEnabled);

    // NEW UPSELL
    if (upSelling?.enabled) this._setUpUpsell(upSelling);

    // OLD REMOVE ITEMS
    if (navigationItems && featuresEnabledMainMenu) this._disableMenuItems(featuresEnabledMainMenu, navigationItems);

    // Check permission to remove admin-security navigation item

    if (true) _log('[navigationItems]', { navigationItems, featuresEnabledMainMenu, blocksEnabled, upSelling });
  }

  private _currentNavigationFlatChilds($item) {
    let item = _cloneDeep($item);
    let rv = [];

    if (item.children) {
      let childs = item.children.map(el => this._currentNavigationFlatChilds(el)).flat(1);
      rv = [...rv, ...childs];
    }

    delete item.children;
    rv.push(item);

    return _uniqueElementsByKey(rv as any, 'id');
  }

  private async addReports() {
    this._reports = this.prismaDynamicEnv.getConf('brandCustomization.reports');

    if (this._reports?.length > 0) {
      //Los repotes que contengan __DATA-QUALITY__ o __FEATURE_FLAG__ se habilitan si FeatureFlag__DEV-01 está habilitado
      let AUTO_ENABLE_FEATURE_FLAG_REPORTS = this.devSettingsService.hasFeatureFlag(FeatureFlagsKeysEnum['FeatureFlag__DEV-01']);

      if (AUTO_ENABLE_FEATURE_FLAG_REPORTS) {
        this._reports.forEach(element => {
          if (element.id?.includes('__DATA-QUALITY__') || element.id?.includes('__FEATURE_FLAG__')) element.enable = true;
        });
      }

      let hasReports = this._reports.some(x => x.id !== 'DASHBOARD' && x.enable && x.url !== '');

      if (hasReports) {
        let dynReports = this._reports
          .filter(x => x.id !== 'DASHBOARD' && x.enable)
          .map(function (report) {
            return {
              id: report.id,
              section: report.section,
              strictSection: Boolean(report.strictSection),
              title: report.description,
              type: 'item',
              url: '/report/' + report.id,
            };
          });

        dynReports.forEach(x => {
          let navigation = this._fuseNavigationService.getNavigationItem(x?.section);

          // Si no hay sección o no hay hijos en la sección, lo cuelga de home o de la sección de reportes (si existe)
          if (x?.section == null || navigation?.children == null) {
            navigation = this._fuseNavigationService.getNavigationItem('home');
            let reports = navigation?.children?.find?.(x => x.id === 'reports');
            if (reports?.children != null) reports.children.push(x);
            if (x?.section != null && x?.section != 'null') console.warn('No Section:', x?.section);
            return;
          }

          // Si de la sección de reportes hay algún hijo que contenga en el id 'reports' lo cuelga de ahí, si no directo en la sección
          let rootReport = navigation?.children?.find?.(n => n?.id?.includes?.('reports')) || navigation;

          // Si el reporte tiene una sección estricta, lo cuelga de la sección exacta
          if (x?.strictSection === true) rootReport = navigation;

          if (rootReport?.children != null) {
            rootReport.children.push(x);
          } else {
            console.warn('No Section children:', x?.id, x?.section, rootReport);
          }
        });
      }
    }
  }

  private async addSectionReport(section: string) {
    let dynReports = this._reports
      .filter(x => x.id !== 'DASHBOARD' && x.enable && x.section === section)
      .map(function (report) {
        return {
          id: report.id,
          title: report.description,
          type: 'item',
          url: '/report/' + report.id,
        };
      });

    let navigation = this._fuseNavigationService.getNavigationItem(section).children;
    let reports = navigation.find(x => x.id === section + '_reports').children;
    dynReports.forEach(x => reports.push(x));
  }

  private updateConfig(auth, config): void {
    this.fuseConfig = config;

    this.fuseConfig.layout.navbar.hidden = !auth;

    // Boxed
    if (this.fuseConfig.layout.width === 'boxed') {
      this.document.body.classList.add('boxed');
    } else {
      this.document.body.classList.remove('boxed');
    }

    // Color theme - Use normal for loop for IE11 compatibility
    for (let i = 0; i < this.document.body.classList.length; i++) {
      const className = this.document.body.classList[i];

      if (className.startsWith('theme-')) {
        this.document.body.classList.remove(className);
      }
    }

    if (this.fuseConfig.colorTheme !== null && !this.document.body.classList.contains(this.fuseConfig.colorTheme)) {
      this.document.body.classList.add(this.fuseConfig.colorTheme);
      if (this._initData) localStorage.setItem(_keyVer + 'colorTheme', this.fuseConfig.colorTheme);
    }
  }

  private doCallbackLogicIfRequired(): void {
    // Will do a callback, if the url has a code and state parameter.
    const codeUrl = localStorage.getItem(_keyVer + 'codeUrl');

    if (!codeUrl || codeUrl.length === 0) {
      return;
    }

    if (_devTest) {
      console.log('%c[localStorage.getItem(codeUrl]', 'color:#f38519', { codeUrl, location_hash: window.location.hash });
    }

    this.oidcSecurityService.authorizedCallbackWithCode(codeUrl);

    // window.setTimeout(() => {
    //     let codeUrl = localStorage.getItem('codeUrl');
    //     this.oidcSecurityService.authorizedCallbackWithCode(codeUrl);
    // }, 1000);
  }

  public toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  // PROMOTIONS PRO-773
  private async _promotionsNavigationConfig() {
    const promotionOldVersion = this.prismaDynamicEnv.getConf('brandCustomization.promotions.promotionOldVersion');
    const showPromotionalMessageV2 = this.devSettingsService.hasFeatureFlag(FeatureFlagsKeysEnum['FeatureFlag__PRO-1421']);
    if (promotionOldVersion) {
      this.markUrlToBlock(this._fuseNavigationService.getNavigationItem('promotionsV2-configurations-rules')?.url); //CORE-25 > Marca la URL como bloqueada
      this.markUrlToBlock(this._fuseNavigationService.getNavigationItem('promotionsV2-configurations-reference-prices')?.url); //CORE-25 > Marca la URL como bloqueada

      this._fuseNavigationService.removeNavigationItem('promotionsV2-configurations-rules');
      this._fuseNavigationService.removeNavigationItem('promotionsV2-configurations-reference-prices');
    }
    if (!showPromotionalMessageV2) {
      this.markUrlToBlock(this._fuseNavigationService.getNavigationItem('promotionsV2-configurations-message-structures')?.url); //CORE-25 > Marca la URL como bloqueada

      this._fuseNavigationService.removeNavigationItem('promotionsV2-configurations-message-structures');
    }

    this._checkPromotionConfigurationsChildren();
  }

  //revisa si la seccionde configuraciones de promociones tiene children, sino la elimina del nav.
  private _checkPromotionConfigurationsChildren() {
    const promotionConfigurationsRoot = this._fuseNavigationService.getNavigationItem('promotionsV2-configurations');
    if (promotionConfigurationsRoot && !(promotionConfigurationsRoot?.children?.length > 0)) {
      this._fuseNavigationService.removeNavigationItem('promotionsV2-configurations');
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
